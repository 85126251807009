<template>
  <div class="menu-body"
    v-if="$oidc.isAuthenticated && $oidc.userProfile && $oidc.userProfile.roles && $oidc.userProfile.roles.length > 0">
    <Loader v-if="showLoader" />
    <Breadcrumb />
    <div class="main-con">
      <div class="mat-info-con" style="justify-content: space-between; align-items: center">
        <div class="plant-dd" style="width: 100%; display: flex">
          <div class="plant-dd" style="margin-left: 10px">
            <label for="committed_year" class="form-label select-lable lable-text"> Committed Year </label>
            <select class="form-control select-text select-text-pd cust-st-icon" id="committed_year"
              v-model="committed_year" @change="committed_year_changed($event)">
              <option v-for="year in committed_year_array" v-bind:key="year" :value="year">
                {{ year }}
              </option>
            </select>
          </div>
          <div class="plant-dd" style="margin-left: 10px">
            <label for="pillar" class="form-label select-lable lable-text"> Plant </label>
            <select class="form-control select-text select-text-pd cust-st-icon" id="plantId" v-model="plantId"
              @change="plantChanged()">
              <option v-for="plant in plantList" v-bind:key="plant.plantId" :value="plant.plantId">{{ plant.plantName }},
                {{ plant.regionCode }}</option>
            </select>
          </div>
        </div>
      </div>
      <div class="mat-info-con">
        <div class="committed-table" v-if="plantId >= 1" style="width: 100%">
          <b-table id="committed-table" class="target-table gn-table-b" responsive :items="committedValues"
            :fields="committedFields">
            <template #head(totalCommittedActions)>
              <div>{{ committed_year }}</div>
            </template>
            <template #cell()="row">
              <span style="cursor: pointer" @click="tablefilterclicked(row)">{{ row.value }}</span>
            </template>
          </b-table>
        </div>
      </div>
      <div class="mat-info-con" style="justify-content: space-between; align-items: center">
        <div class="plant-dd" style="width: 100%; display: flex">
          <div class="plant-dd" style="margin-left: 10px">
            <label for="pillar" class="form-label select-lable lable-text"> Pillar </label>
            <select class="form-control select-text select-text-pd cust-st-icon" id="pillar" v-model="pillar"
              @change="onchangepillar(pillar)">
              <option value="0">Select Pillar</option>
              <option value="-1">All Pillars</option>
              <option v-for="eachPillar in pillars" :key="eachPillar.pillarId" :value="eachPillar.pillarId">
                {{ eachPillar.pillarName }}
              </option>
            </select>
          </div>
        </div>
        <div style="display: flex" v-if="action_item.length > 0">
          <div style="width: 76px">
            <div class="acti-item-filter funnel-con" style="float: left" v-if="this.pillar == -1">
              <button class="action-btn primary-color" :aria-controls="allPillarCodes"
                style="background: transparent; margin-right: 0px" type="button" v-b-tooltip.hover title="Expand All"
                @click="checkToggleExpandCollapse('Expand All', allPillarIds)">
                <img alt="down" src="../../../assets/gov-down2.svg" />
              </button>
            </div>
            <div class="acti-item-filter funnel-con" style="float: left" v-if="this.pillar == -1">
              <button class="action-btn primary-color" :aria-controls="allPillarCodes"
                style="background: transparent; margin-right: 0px; margin-left: 1rem" type="button" v-b-tooltip.hover
                title="Collapse All" @click="checkToggleExpandCollapse('Collapse All', allPillarIds)">
                <img alt="up" src="../../../assets/gov-up2.svg" />
              </button>
            </div>
          </div>
          <div class="acti-item-filter funnel-con">
            <button class="download-view action-btn" style="background: transparent; margin-right: 0px; margin-left: 1rem"
              type="button" v-b-tooltip.hover title="Export to Excel" @click="exportToExcel">
              <img alt="download" src="../../../assets/download_icon.svg" />
            </button>
          </div>
          <div class="acti-item-filter funnel-con">
            <div class="action-btn primary-color" style="margin-right: 0px; margin-left: 1rem"
              v-b-modal.action-home-screen-filter v-b-tooltip.hover title="Sort" @click="setFilter2()">
              <img alt="Sorting" src="../../../assets/sorting.svg" />
            </div>
          </div>
          <div class="acti-item-filter funnel-con">
            <div class="action-btn primary-color" v-b-modal.action-home-screen-filter
              style="margin-right: 0px; margin-left: 1rem" v-b-tooltip.hover title="Filter"
              @click="filterHeader = 'Filters'">
              <b-icon icon="funnel"></b-icon>
            </div>
          </div>
        </div>
      </div>

      <b-modal id="action-home-screen-filter" ref="my-modal" :hide-header="true" :hide-footer="true" dialog-class="right">
        <action-home-screen-filter :filterHeader="filterHeader" :hideModal="hideModal" :filterArray="filterArray"
          :filterSelected="filterSelected" :applyFilter="onApplyClick" :resetFilter="resetFilter"
          :changedFilter="changedFilter" :onContext="onContext" :onSelect="onSelect" :onRemove="onRemove" />
      </b-modal>

      <!---for loop start --->

      <div id="action-home-table-id">
        <div class="accordion" role="tablist" v-if="this.pillar == -1">
          <!--All PIllars-->
          <div v-for="(actionMain, actionMainIndex) in action_item" :key="(actionMain, actionMainIndex)">
            <div v-bind:id="actionMain.pillarId">
              <br />

              <div class="level-status target-card-lable principle-name-label" :value="actionMain.pillarName"
                style="padding-left: 30px"
                @click="checkToggle(collapsed_values[actionMain.pillarId], actionMain.pillarId)">
                <b-button :aria-controls="actionMain.pillarCode"
                  :aria-expanded="collapsed_values[actionMain.pillarId] ? 'true' : 'false'"
                  style="cursor: pointer; background-color: white; border-color: white">
                  <img alt="down" v-if="!collapsed_values[actionMain.pillarId]" style="cursor: pointer"
                    src="@/assets/gov-down.svg" />
                  <img alt="up" v-else style="cursor: pointer" src="@/assets/gov-up.svg" />
                </b-button>

                {{ actionMain.pillarName + " (" + actionMain.pillarCode + ")" }}
                <span class="pillar-count"
                  v-if="getPracticesCount(actionMain.pillarId, actionMain.pillarName, actionMain.pillarInfo) > 0">
                  {{ practices_Count[actionMain.pillarId] | doubleDigit }}
                </span>
              </div>

              <b-collapse v-bind:id="actionMain.pillarCode" v-model="collapsed_values[actionMain.pillarId]">
                <div v-for="(action, pillarInfoIndex) in actionMain.pillarInfo" :key="(action, pillarInfoIndex)">
                  <div class="level-con" style="margin-left: 2rem; margin-right: 2rem">
                    <div class="row level-row" style="justify-content: space-between">
                      <div class="row" v-bind:id="action.principleId" style="padding-left: 15px">
                        <div class="ellipse" :value="action.principleNumber">
                          <label class="ellipse-text">
                            <strong>{{ action.principleNumber }}</strong>
                          </label>
                        </div>
                        <div class="level-status target-card-lable principle-name-label" :value="action.principleName"
                          style="padding-left: 10px">
                          {{ action.principleName }}
                        </div>
                      </div>

                      <div style="margin-right: 10px; display: flex">
                        <div>
                          <b-button id="editButton" style="font-family: 'BarlowM', sans-serif"
                            class="rounded-pill btn-action"
                            :disabled="!checkPersonForAsmt || action.principleActiveStatus == 'N'" pill variant="primary"
                            @click="addMoreAllPillar(action.principleId, actionMain.pillarId)">
                            Add Actions
                          </b-button>
                        </div>
                      </div>
                    </div>

                    <template v-if="action.practiceData.length > 0">
                      <div class="view-asmt border border-0" v-for="(practice, practiceIndex) in action.practiceData"
                        :key="practice.practiceId">
                        <b-table id="action-home-table" class="action-home-table" responsive hover
                          :items="practice.actionItems" :fields="fields" v-if="practice.actionItems.length > 0">
                          <template #head(custom_column)>
                            <div class="mm-menu" style="width: 2rem">
                              <div class="dropdown-custome dd-menu-items maturity-dd dropstart" style="width: 2rem">
                                <button class="btn btn-secondary" type="button" id="dropdownList"
                                  data-bs-toggle="dropdown" aria-expanded="false" style="padding: 0; margin: 0">
                                  <img alt="more" id="dropdownList" src="../../../assets/More.svg" />
                                </button>
                                <ul class="dropdown-menu" aria-labelledby="dropdownList" style="text-align: left">
                                  <li @click="editPracticeAllPillar(practice, action.principleId, actionMain.pillarId)">
                                    <button id="edit-li">Edit</button>
                                  </li>
                                </ul>
                              </div>
                            </div>
                          </template>
                          <template #head(pId)>
                            <div>
                              {{ action.principleNumber + "." + practice.levelId }}
                            </div>
                          </template>
                          <template #head(committedDate)>
                            <span> Committed Date </span>

                            <div style="margin-top: 10px">
                              {{ practice.committedDate | dateFormater }}
                            </div>
                          </template>
                          <template #head(completedDate)>
                            <span> Completed Date </span>

                            <div style="white-space: pre; margin-top: 10px">
                              {{ practice.actuaCompletionDate | dateFormater }}
                            </div>
                          </template>

                          <template #head(status)>
                            <span> Status </span>
                            <div style="margin-top: 10px; word-break: break-all; display: flex"
                              v-if="checkMajorChangeAllPillar(actionMainIndex, pillarInfoIndex, practiceIndex, i)">
                              <img :alt="status + index" v-for="(status, index) in practice.statusNameArr"
                                :key="status + index" :src="iconSelected(status)" :title="status"
                                style="width: 1.6rem; margin-right: 0.3rem" />

                              {{ practiceStatusFunc(practice.statusName, practice.isMajorChange) }}
                            </div>
                            <div style="margin-top: 10px" v-else>
                              <img :alt="practice.statusName" :src="iconSelected(practice.statusName)"
                                style="width: 1.6rem; margin-right: 0.3rem" />
                              {{ practiceStatusFunc(practice.statusName, null) }}
                            </div>
                          </template>

                          <template #cell(levelName)="row">
                            <div>
                              {{ action.principleNumber + "." + row.item.levelName + "." + row.item.actionName }}
                            </div>
                          </template>
                          <template #cell(actionDescription)="row">
                            <div>
                              {{ actionDescriptionT(row.item.actionDescription) }}
                            </div>
                          </template>

                          <template #cell(committedDate)="row">
                            <div class="dateCol">
                              {{ row.item.committedDate | dateFormater }}
                            </div>
                          </template>
                          <template #cell(completedDate)="row">
                            <div class="dateCol">
                              {{ row.item.completedDate | dateFormater }}
                            </div>
                          </template>
                          <template #cell(status)="row">
                            <div class="status-col"><img alt="more" class="status-icons"
                                :src="iconSelected(row.item.status)" />{{ row.item.status }}</div>
                          </template>
                          <template #cell(custom_column)="row">
                            <div class="mm-menu" style="width: 2rem">
                              <div class="dropdown-custome dd-menu-items maturity-dd dropstart" style="width: 2rem">
                                <button class="btn btn-secondary" type="button" id="dropdownList"
                                  data-bs-toggle="dropdown" aria-expanded="false" style="padding: 0; margin: 0">
                                  <img alt="more" id="dropdownList" @click="(actionID = row.item.actionId),
                                    (levelId = row.item.levelName),
                                    (principleIds = action.principleId),
                                    (practiceId = row.item.practiceId),
                                    (statusId = row.item.statusId)
                                    " src="../../../assets/More.svg" />
                                </button>
                                <ul class="dropdown-menu" aria-labelledby="dropdownList" style="text-align: left">
                                  <li @click="viewAction()">
                                    <button style="background: none; border: none">View</button>
                                  </li>
                                  <li>
                                    <button @click="editActionAllPillar(actionMain.pillarId)" id="edit-li" :disabled="!checkPersonaActionEdit ||
                                      action.principleActiveStatus == 'N' ||
                                      //row.item.status.toUpperCase() == 'COMPLETED' ||
                                      !isForEditActionCalibration(row.item.status)
                                      ">
                                      Edit
                                    </button> -->
                                    <button @click="editActionAllPillar(actionMain.pillarId)
                                      " id="edit-li" :disabled="!checkPersonaActionEdit ||
    action.principleActiveStatus == 'N' ||
    //row.item.status.toUpperCase() == 'COMPLETED' ||
    !isForEditActionCalibration(row.item.status)
    ">
                                      Edit
                                    </button>
                                  </li>
                                </ul>
                              </div>
                            </div>
                          </template>
                        </b-table>
                      </div>
                      <div v-if="action.practiceData.length == 0" class="no-data">
                        <img alt="no data" src="../../../assets/No_Data.svg" />
                        <span class="ed-date">No Records Found</span>
                      </div>
                    </template>
                  </div>
                </div>
              </b-collapse>
            </div>
          </div>
        </div>
        <div v-else>
          <!--By PIllar-->
          <div v-for="(action, i) in action_item" :key="(action, i)">
            <div class="level-con" style="margin-left: 2rem; margin-right: 2rem">
              <div class="row level-row" style="justify-content: space-between">
                <div class="row" v-bind:id="action.principleId" style="padding-left: 15px">
                  <div class="ellipse" :value="action.principleNumber">
                    <label class="ellipse-text">
                      <strong> {{ action.principleNumber }}</strong>
                    </label>
                  </div>
                  <div class="level-status target-card-lable principle-name-label" :value="action.principleName"
                    style="padding-left: 10px">
                    {{ action.principleName }}
                  </div>
                </div>

                <div style="margin-right: 10px; display: flex">
                  <div>
                    <b-button id="editButton" style="font-family: 'BarlowM', sans-serif" class="rounded-pill btn-action"
                      :disabled="!checkPersonForAsmt || action.principleActiveStatus == 'N'" pill variant="primary"
                      @click="addMore(action.principleId)">
                      Add Actions
                    </b-button>
                  </div>
                </div>
              </div>
              <template v-if="action.practiceData.length > 0">
                <div class="view-asmt border border-0" v-for="(practice, practiceIndex) in action.practiceData"
                  :key="practice.practiceId">
                  <b-table id="action-home-table" class="action-home-table" responsive hover :items="practice.actionItems"
                    :fields="fields" v-if="practice.actionItems.length > 0">
                    <template #head(custom_column)>
                      <div class="mm-menu" style="width: 2rem">
                        <div class="dropdown-custome dd-menu-items maturity-dd dropstart" style="width: 2rem">
                          <button class="btn btn-secondary" type="button" id="dropdownList" data-bs-toggle="dropdown"
                            aria-expanded="false" style="padding: 0; margin: 0">
                            <img alt="more" id="dropdownList" src="../../../assets/More.svg" />
                          </button>
                          <ul class="dropdown-menu" aria-labelledby="dropdownList" style="text-align: left">
                            <li @click="editPractice(practice, action.principleId)">
                              <button id="edit-li">Edit</button>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </template>
                    <template #head(pId)>
                      <div>
                        {{ action.principleNumber + "." + practice.levelId }}
                      </div>
                    </template>
                    <template #head(committedDate)>
                      <span> Committed Date </span>

                      <div style="margin-top: 10px">
                        {{ practice.committedDate | dateFormater }}
                      </div>
                    </template>
                    <template #head(completedDate)>
                      <span> Completed Date </span>

                      <div style="white-space: pre; margin-top: 10px">
                        {{ practice.actuaCompletionDate | dateFormater }}
                      </div>
                    </template>
                    <template #head(status)>
                      <span> Status </span>
                      <div style="margin-top: 10px; word-break: break-all; display: flex"
                        v-if="checkMajorChange(practiceIndex, i)">
                        <img alt="more" v-for="(status, index) in practice.statusNameArr" :key="status + index"
                          :src="iconSelected(status)" :title="status" style="width: 1.6rem; margin-right: 0.3rem" />

                        {{ practiceStatusFunc(practice.statusName, practice.isMajorChange) }}
                      </div>
                      <div style="margin-top: 10px" v-else>
                        <img alt="more" :src="iconSelected(practice.statusName)"
                          style="width: 1.6rem; margin-right: 0.3rem" />
                        {{ practiceStatusFunc(practice.statusName, null) }}
                      </div>
                    </template>

                    <template #cell(levelName)="row">
                      <div>
                        {{ action.principleNumber + "." + row.item.levelName + "." + row.item.actionName }}
                      </div>
                    </template>
                    <template #cell(actionDescription)="row">
                      <div>
                        {{ actionDescriptionT(row.item.actionDescription) }}
                      </div>
                    </template>

                    <template #cell(committedDate)="row">
                      <div class="dateCol">
                        {{ row.item.committedDate | dateFormater }}
                      </div>
                    </template>
                    <template #cell(completedDate)="row">
                      <div class="dateCol">
                        {{ row.item.completedDate | dateFormater }}
                      </div>
                    </template>
                    <template #cell(status)="row">
                      <div class="status-col"><img alt="more" class="status-icons"
                          :src="iconSelected(row.item.status)" />{{ row.item.status }}</div>
                    </template>
                    <template #cell(custom_column)="row">
                      <div class="mm-menu" style="width: 2rem">
                        <div class="dropdown-custome dd-menu-items maturity-dd dropstart" style="width: 2rem">
                          <button class="btn btn-secondary" type="button" id="dropdownList" data-bs-toggle="dropdown"
                            aria-expanded="false" style="padding: 0; margin: 0">
                            <img alt="more" id="dropdownList" @click="(actionID = row.item.actionId),
                              (levelId = row.item.levelName),
                              (principleIds = action.principleId),
                              (practiceId = row.item.practiceId),
                              (statusId = row.item.statusId)
                              " src="../../../assets/More.svg" />
                          </button>
                          <ul class="dropdown-menu" aria-labelledby="dropdownList" style="text-align: left">
                            <li @click="viewAction()">
                              <button style="background: none; border: none">View</button>
                            </li>
                            <li>
                              <button @click="editAction()" id="edit-li" :disabled="!checkPersonaActionEdit ||
                                action.principleActiveStatus == 'N' ||
                                //row.item.status.toUpperCase() == 'COMPLETED' ||
                                !isForEditActionCalibration(row.item.status)
                                ">
                                Edit
                              </button>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </template>
                  </b-table>
                </div>
                <div v-if="action.practiceData.length == 0" class="no-data">
                  <img alt="no data" src="../../../assets/No_Data.svg" />
                  <span class="ed-date">No Records Found</span>
                </div>
              </template>
            </div>
          </div>
        </div>
      </div>
      <!---for loop end --->
      <div v-if="(!action_item.length && success == false) || (!action_item.length && success == true)" class="no-data">
        <img alt="no data" src="../../../assets/No_Data.svg" />
        <span class="ed-date">No Records Found</span>
      </div>
    </div>
    <a id="hyLink" href="#3"></a>
  </div>
</template>
<script>
import "../../../common/css/CommonStyle.css";
import "../../../common/css/font-categories.css";
import Breadcrumb from "../../../components/common/Breadcrumb.vue";
import Role from "../../../common/constants/roles";
import Api from "../../../common/constants/api";
import ApiCalls from "../../../common/api/apiCalls";
import Loader from "../../../components/common/Loader.vue";
import Helper from "../../../common/helper/";
import ActionHomeScreenFilter from "./ActionHomeScreenFilter.vue";
import moment from "moment";
import XLSX from "xlsx";
import OnTrack from "../../../assets/blue-tick.svg";
import OffTrack from "../../../assets/off-track.svg";
import Completed from "../../../assets/maturity-cmpl.svg";
import Committed from "../../../assets/commited.svg";
import NotCommitedDate from "../../../assets/notcommiteddate.svg";
import InProgress from "../../../assets/in-Progress.svg";
import NotStarted from "../../../assets/maturity-not-start.svg";
import NotSustained from "../../../assets/yellow-tick.svg";
import MajorChanges from "../../../assets/red-warning.svg";
import Deleted from "../../../assets/deleted.svg";
import { mapGetters } from "vuex";

export default {
  name: "Action",
  // props: ['id','ids','pillarss','screen','plant','levelName','principle'],
  data() {
    return {
      totalCommittedActionsValue: 0,
      myDetails: {},
      isUserAllowedToCalibrateAction: "N",
      committedFields: [
        {
          key: "totalCommittedActions",
          // label: "Total Committed Practices",
          sortable: false
        },
        {
          key: "totalCount",
          label: "Total",
          sortable: false
        },
        {
          key: "January",
          label: "Jan",
          sortable: false,
          variant: "success"
        },
        {
          key: "February",
          label: "Feb",
          sortable: false,
          variant: "success"
        },
        {
          key: "March",
          label: "Mar",
          sortable: false,
          variant: "success"
        },
        {
          key: "April",
          label: "Apr",
          sortable: false,
          variant: "success"
        },
        {
          key: "May",
          label: "May",
          sortable: false,
          variant: "success"
        },
        {
          key: "June",
          label: "Jun",
          sortable: false,
          variant: "success"
        },
        {
          key: "July",
          label: "Jul",
          sortable: false,
          variant: "success"
        },
        {
          key: "August",
          label: "Aug",
          sortable: false,
          variant: "success"
        },
        {
          key: "September",
          label: "Sep",
          sortable: false,
          variant: "success"
        },
        {
          key: "October",
          label: "Oct",
          sortable: false,
          variant: "success"
        },
        {
          key: "November",
          label: "Nov",
          sortable: false,
          variant: "success"
        },
        {
          key: "December",
          label: "Dec",
          sortable: false,
          variant: "success"
        }
      ],
      committedValues: [],
      committed_year: new Date().getFullYear(),
      rMin: new Date("2022-01-01"),
      rMax: new Date("2022-12-31"),
      committed_year_array: Helper.yearArrayFrom2019(),
      success: "",
      roles: Role,
      showLoader: true,
      statusId: "",
      practiceId: "",
      currentYear: "",
      pillar: 1,
      pillarOnFirstLoad: true,
      pillars: [],
      plantList: [],
      pillarName: "",
      plantName: "",
      plantDetails: {},
      plantId: "",
      apiCount: 0,
      action_item: [],
      principleId: "",
      actionID: "",
      totalCommitted: "",
      actionReq: "",
      totalComp: "",
      actionIDs: "",
      principleIds: "",
      levelId: "",
      actionName: "",
      viewPlant: "",
      viewPillar: "",
      filterHeader: "",
      dynamicFields: [],
      sort: "",
      order: "",
      fields: [
        {
          key: "pId",
          label: "",
          sortable: false,
          tdClass: "border border-0",
          thStyle: { width: "2rem" }
        },
        {
          key: "levelName",
          label: "#",
          sortable: true,
          tdClass: "border border-0"
        },
        // {
        //   key: "actionName",
        //   label: " ",
        //   sortable: false,
        //   tdClass: "border border-0",
        //   thStyle: { width: "12rem" },
        // },
        {
          key: "actionDescription",
          label: "SMART Action",
          sortable: true,
          tdClass: "border border-0"
        },
        {
          key: "ownerName",
          label: "Owner",
          sortable: false,
          tdClass: "border border-0"
        },
        {
          key: "committedDate",
          label: "Committed Date",
          sortable: true,
          tdClass: "border border-0"
        },
        {
          key: "completedDate",
          label: "Completed Date",
          sortable: true,
          tdClass: "border border-0"
        },
        {
          key: "status",
          label: "Status",
          sortable: false,
          tdClass: "border border-0"
        },
        {
          key: "custom_column",
          label: " ",
          sortable: false,
          tdClass: "border border-0"
        }
      ],
      selectedPrincipleId: null,
      backupActionItems: [],
      filterArray: [],
      filterSelected: [],
      checkNoCompletedDate: false,
      regionName: null,
      collapsed: {
        1: true,
        3: true,
        4: true,
        5: true,
        6: true,
        7: true,
        8: true,
        9: true,
        10: true,
        11: true,
        12: true,
        13: true,
        14: true
      },
      collapsed_values: {},
      allPillarCodes: [],
      allPillarIds: [],
      //principleToggleCollapseAll: "Collapse All",
      //principleToggleExpandAll: "Expand All",
      principleToggleName: "Collapse All",
      visible: true,
      allpillarId: "",
      practices_Count: {}
    };
  },

  components: {
    Breadcrumb,
    Loader,
    ActionHomeScreenFilter,
    OnTrack,
    OffTrack,
    Completed,
    Committed,
    NotCommitedDate,
    InProgress,
    NotStarted,
    NotSustained
  },

  beforeCreate() {
    this.$store.dispatch("selectedMenu", {
      main: 2,
      subMenu: 3
    });
    this.$store.dispatch("breadcrumb", {
      title: "Plant Optimization Action Tracker",
      breadcrumb: [
        { name: "Home / ", primary: true, redirect: "/dashboard" },
        { name: "Plant Optimization Action Tracker", primary: false }
      ]
    });
  },
  mounted() {
    this.$nextTick(function () {
      // Code that will run only after the
      // entire view has been rendered
      setTimeout(() => {
        let princId = Helper.getPrincipleId();
        document.getElementById("hyLink").href = "#" + princId;
        document.getElementById("hyLink").click();
      }, 3000);
    });
  },
  created() {
    let minDate = this.committed_year + "-01-01";
    let maxDate = this.committed_year + "-12-31";
    this.rMin = new Date(minDate);
    this.rMax = new Date(maxDate);
    console.log("created action tracker");
    console.log(this.$route.params);
    this.plantId = this.$route.params.plantId;
    this.pillar = this.$route.params.pillarId;
    this.getPlantList();
    this.getCommittedActionDetails();
    this.getPillarListData();
    this.checkUserProfile();
  },
  computed: {
    ...mapGetters(["plantSelectedInTargetHome", "loggedInUserData"]),

    checkPersonForAsmt() {
      return !!(
        this.$oidc.userProfile.roles.includes(this.roles.PLANTPOLEADER) ||
        this.$oidc.userProfile.roles.includes(this.roles.PLANTPILLARLEADER) ||
        this.$oidc.userProfile.roles.includes(this.roles.PLANTSUBPILLARLEADER) ||
        this.$oidc.userProfile.roles.includes(this.roles.REGIONALPOLEADER) ||
        this.$oidc.userProfile.roles.includes(this.roles.USER) ||
        this.$oidc.userProfile.roles.includes(this.roles.PLANTMANAGER) ||
        this.$oidc.userProfile.roles.includes(this.roles.REGIONALPILLARLEADER)
      );
    },
    checkPersonaActionEdit() {
      return !!(
        this.$oidc.userProfile.roles.includes(this.roles.PLANTMANAGER) ||
        this.$oidc.userProfile.roles.includes(this.roles.PLANTPOLEADER) ||
        this.$oidc.userProfile.roles.includes(this.roles.PLANTPILLARLEADER) ||
        this.$oidc.userProfile.roles.includes(this.roles.PLANTSUBPILLARLEADER) ||
        this.$oidc.userProfile.roles.includes(this.roles.USER) ||
        this.$oidc.userProfile.roles.includes(this.roles.REGIONALPOLEADER) ||
        this.$oidc.userProfile.roles.includes(this.roles.REGIONALPILLARLEADER)
      );
    }
  },
  methods: {
    tablefilterclicked(row) {
      let jsonMonth = {
        Jan: 1,
        Feb: 2,
        Mar: 3,
        Apr: 4,
        May: 5,
        Jun: 6,
        Jul: 7,
        Aug: 8,
        Sep: 9,
        Oct: 10,
        Nov: 11,
        Dec: 12
      };
      // console.log(row.item.totalCommittedActions.includes('Committed'),row,row.item.totalCommittedActions)
      if (row.item.totalCommittedActions.includes("Committed") && row.value > 0) {
        // console.log('Committed',row.field.label,row)
        this.pillar = -1;
        if (row.field.label == "Total") {
          this.getActionItemAllPillar(13, 0);
        } else {
          this.getActionItemAllPillar(jsonMonth[row.field.label], 0);
        }
      } else if (row.value > 0) {
        //console.log('Completed',row.field.label,row)
        this.pillar = -1;
        if (row.field.label == "Total") {
          this.getActionItemAllPillar(0, 13);
        } else {
          this.getActionItemAllPillar(0, jsonMonth[row.field.label]);
        }
      } else {
        this.pillar = 0;
        this.action_item = [];
      }
    },
    checkUserProfile() {
      let currentUserEmail = this.$oidc.userProfile.email;
      ApiCalls.httpCallDetails(Api.MYPROFILE + currentUserEmail, "get").then((data) => {
        this.myDetails = data.data;
      });
    },
    isForEditActionCalibration(actionStatus) {
      console.log("this.myDetails.allowCalibrate: " + this.myDetails.allowCalibrate);
      if (actionStatus == "Completed") {
        console.log("is Completed? Yes");
        return this.$oidc.userProfile.roles.includes(this.roles.GLOBALPILLARLEADER) || this.myDetails.allowCalibrate == "Y";
      } else {
        console.log("is Completed? No");
        return true;
      }
    },
    exportToExcel() {
      // On Click Excel download button
      let sheetData = [];
      if (this.pillar > 0) {
        this.action_item.forEach((i) => {
          if (i.practiceData.length > 0) {
            let practiceData = i.practiceData;
            practiceData.forEach((j, indexPractice) => {
              if (j.actionItems && j.actionItems.length > 0) {
                let actionItems = j.actionItems;
                let pillarIndex = this.pillars.findIndex((k) => k.pillarId == this.pillar);
                let plantIndex = this.plantList.findIndex((k) => k.plantId == this.plantId);
                actionItems.forEach((k) => {
                  let jsonSheet = {};
                  jsonSheet["Plant Name"] = this.plantList[plantIndex].plantName;
                  jsonSheet["Pillar Name"] = this.pillars[pillarIndex].pillarName;
                  jsonSheet["Principle Number"] = i.principleNumber;
                  jsonSheet["Principle Name"] = i.principleName;

                  jsonSheet["Action Number"] = i.principleNumber + "." + k.levelName + "." + k.actionName;
                  jsonSheet["Action Description"] = k.actionDescription;
                  jsonSheet["Committed Date"] = moment(k.committedDate).format("DD-MM-YYYY");
                  jsonSheet["Completed Date"] = k.completedDate ? moment(k.completedDate).format("DD-MM-YYYY") : "";
                  jsonSheet["Status"] = k.status;
                  jsonSheet["OwnerName"] = k.ownerName;
                  sheetData.push(jsonSheet);
                });
              }
            });
          }
        });
      } else if (this.pillar == -1) {
        this.action_item.forEach((i) => {
          let pillarIndex;
          pillarIndex = this.pillars.findIndex((ind) => ind.pillarId == i.pillarId);
          i.pillarInfo.forEach((m) => {
            if (m.practiceData.length > 0) {
              let practiceData = m.practiceData;
              practiceData.forEach((j, indexPractice) => {
                if (j.actionItems && j.actionItems.length > 0) {
                  let actionItems = j.actionItems;

                  let plantIndex = this.plantList.findIndex((i) => i.plantId == this.plantId);

                  actionItems.forEach((k) => {
                    let jsonSheet = {};
                    jsonSheet["Plant Name"] = this.plantList[plantIndex].plantName;
                    jsonSheet["Pillar Name"] = this.pillars[pillarIndex].pillarName;
                    jsonSheet["Principle Number"] = m.principleNumber;
                    jsonSheet["Principle Name"] = m.principleName;

                    jsonSheet["Action Number"] = m.principleNumber + "." + k.levelName + "." + k.actionName;
                    jsonSheet["Action Description"] = k.actionDescription;
                    jsonSheet["Committed Date"] = moment(k.committedDate).format("DD-MM-YYYY");
                    jsonSheet["Completed Date"] = k.completedDate ? moment(k.completedDate).format("DD-MM-YYYY") : "";
                    jsonSheet["Status"] = k.status;
                    jsonSheet["OwnerName"] = k.ownerName;
                    sheetData.push(jsonSheet);
                  });
                }
              });
            }
          });
        });
      }
      // this.pillarsData.forEach((i) => {
      //   let obj = {};
      //   for (const key in this.pillarFields) {
      //     if (
      //       Object.hasOwnProperty.call(this.pillarFields, key) &&
      //       !Object.hasOwnProperty.call(this.pillarFields[key], "field")
      //     ) {
      //       const element = this.pillarFields[key];
      //       obj[key] = i[element] ? i[element] : "";
      //     } else {
      //       const element = this.pillarFields[key];
      //       obj[key] = i[element.field].length
      //         ? element.callback(i[element.field])
      //         : "";
      //     }
      //   }
      //   sheetData.push(obj);
      // });
      //console.log(sheetData);
      // export json to Worksheet of Excel
      // only array possible
      let sheet = XLSX.utils.json_to_sheet(sheetData);

      let wb = XLSX.utils.book_new(); // make Workbook of Excel

      // add Worksheet to Workbook
      // Workbook contains one or more worksheets
      XLSX.utils.book_append_sheet(wb, sheet, "sheet"); // sheetAName is name of Worksheet
      // XLSX.utils.book_append_sheet(wb, pokemonWS, 'pokemons')

      // export Excel file
      XLSX.writeFile(wb, "Action Details.xlsx"); // name of the file is 'book.xlsx'
    },
    getCommittedActionDetails() {
      console.log("getCommittedActionDetails");
      if (this.plantId === "0" || this.plantId === 0 || this.plantId === "" || this.plantId === undefined || this.plantId === null) {
        this.plantId = 1;
      }
      ApiCalls.httpCallDetails(Api.TARGETCOMMITTED + this.plantId + "/" + this.committed_year, "get").then((data) => {
        this.committedValues = data.data.commitedAction;
        this.committedValues.totalCount = data.data.commitedAction.TotalCommittedActions;
        this.committedValues["totalCommittedActions"] = "#Committed Practices";
        this.totalCommittedActionsValue = data.data.commitedAction.TotalCommittedActions;
        this.totalCompletedActionsValue = data.data.completedActions.TotalCommittedActions;
        this.committedValues = [this.committedValues];
        data.data.completedActions.totalCommittedActions = "#Completed Practices";
        data.data.completedActions.totalCount = data.data.completedActions.TotalCommittedActions;
        this.committedValues.push(data.data.completedActions);
        this.showLoader = false;
      });
    },
    checkMajorChange(practiceIndex, i) {
      let statusName = this.action_item[i].practiceData[practiceIndex].statusName;
      let isMajorChangeTrue = this.action_item[i].practiceData[practiceIndex].isMajorChange;
      if (statusName.match("(Major Change)")) {
        this.action_item[i].practiceData[practiceIndex].statusNameArr = [
          statusName.substr(0, statusName.match("(Major Change)").index - 2),
          statusName.match("(Major Change)")[0] + "s"
        ];
        return true;
      } else if (isMajorChangeTrue) {
        this.action_item[i].practiceData[practiceIndex].statusNameArr = [this.action_item[i].practiceData[practiceIndex].statusName, "Major Changes"];
        return true;
      }
      return false;
    },
    checkMajorChangeAllPillar(actionMainIndex, pillarInfoIndex, practiceIndex, i) {
      let statusName = this.action_item[actionMainIndex].pillarInfo[pillarInfoIndex].practiceData[practiceIndex].statusName;
      let isMajorChangeTrue = this.action_item[actionMainIndex].pillarInfo[pillarInfoIndex].practiceData[practiceIndex].isMajorChange;
      if (statusName.match("(Major Change)")) {
        this.action_item[actionMainIndex].pillarInfo[pillarInfoIndex].practiceData[practiceIndex].statusNameArr = [
          statusName.substr(0, statusName.match("(Major Change)").index - 2),
          statusName.match("(Major Change)")[0] + "s"
        ];
        return true;
      } else if (isMajorChangeTrue) {
        this.action_item[actionMainIndex].pillarInfo[pillarInfoIndex].practiceData[practiceIndex].statusNameArr = [
          this.action_item[actionMainIndex].pillarInfo[pillarInfoIndex].practiceData[practiceIndex].statusName,
          "Major Changes"
        ];
        return true;
      }
      return false;
    },
    practiceStatusFunc(statusname, isMajorChange) {
      if ((statusname == "Off Track" || statusname == "On Track") && isMajorChange) {
        return "In Progress (Major Change)";
      } else if ((statusname == "Off Track" || statusname == "On Track") && !isMajorChange) {
        return "In Progress";
      } else if (statusname == "Not Sustained (Major Change)") {
        return "Not Sustained";
      } else {
        return statusname;
      }
    },
    editPractice(practice, principleId) {
      // path: '/take-assessment/:id/:ids/:pillarName/:screen/:plantName/:levelId/:principle',
      // console.log(practice);
      let indexP = this.plantList.findIndex((val) => Number(val.plantId) === Number(this.plantId));
      if (indexP !== -1) this.plantName = this.plantList[indexP].plantName + ", " + this.plantList[indexP].regionName;
      indexP = this.pillars.findIndex((val) => Number(val.pillarId) === Number(this.pillar));
      if (indexP !== -1) this.pillarName = this.pillars[indexP].pillarName;

      this.$router.push(
        "/take-assessment/" + this.plantId + "/" + this.pillar + "/" + this.pillarName + "/" + "action" + "/" + this.plantName + "/" + practice.levelId + "/" + principleId
      );
    },
    editPracticeAllPillar(practice, principleId, pillarId) {
      // path: '/take-assessment/:id/:ids/:pillarName/:screen/:plantName/:levelId/:principle',
      // console.log(practice);
      let indexP = this.plantList.findIndex((val) => Number(val.plantId) === Number(this.plantId));
      if (indexP !== -1) this.plantName = this.plantList[indexP].plantName + ", " + this.plantList[indexP].regionName;

      indexP = this.pillars.findIndex((val) => Number(val.pillarId) === Number(pillarId));
      if (indexP !== -1) this.pillarName = this.pillars[indexP].pillarName;

      this.$router.push(
        "/take-assessment/" + this.plantId + "/" + pillarId + "/" + this.pillarName + "/" + "action" + "/" + this.plantName + "/" + practice.levelId + "/" + principleId
      );
    },
    viewPractice() {
      // path: '/view-assessment/:plantId/:pillarId/:plantName',
      let indexP = this.plantList.findIndex((val) => Number(val.plantId) === Number(this.plantId));
      if (indexP !== -1) this.plantName = this.plantList[indexP].plantName + ", " + this.plantList[indexP].regionName;
      this.$router.push("/view-assessment/" + this.plantId + "/" + this.pillar + "/" + this.plantName);
    },
    actionIDD(actionP, actionL, actionN) {
      return actionP + "." + actionL + "." + actionN;
    },
    actionDescriptionT(actionDesc) {
      return actionDesc.length < 50 ? actionDesc : actionDesc.substr(0, 45) + "...";
    },
    addMore(principleId) {
      let pillarName = this.pillars.filter((item, i) => {
        if (item.pillarId == this.pillar) {
          return item.pillarName;
        }
      });
      Helper.setPrincipleId(principleId);
      this.$router.push("/add-action/" + this.plantId + "/" + this.pillar + "/" + principleId + "/" + pillarName[0].pillarName);
    },
    addMoreAllPillar(principleId, pillarId) {
      this.allpillarId = this.pillar;
      let pillarName = this.pillars.filter((item, i) => {
        if (item.pillarId == pillarId) {
          return item.pillarName;
        }
      });

      Helper.setPrincipleId(principleId);
      this.$router.push("/add-action/" + this.plantId + "/" + pillarId + "&" + this.allpillarId + "/" + principleId + "/" + pillarName[0].pillarName);
    },
    viewAction() {
      this.$router.push("/view-action/action/" + this.actionID + "/" + this.plantId + "/" + this.pillar);
    },
    editAction() {
      let index = this.pillars.findIndex((val) => Number(val.pillarId) === Number(this.pillar));
      if (index !== -1) this.pillarName = this.pillars[index].pillarName;

      let indexP = this.plantList.findIndex((val) => Number(val.plantId) === Number(this.plantId));
      if (indexP !== -1) this.plantName = this.plantList[indexP].plantName + ", " + this.plantList[indexP].regionName;
      this.$router.push(
        "/add-new-action/" +
        this.plantId +
        "/" +
        this.pillar +
        "/" +
        this.principleIds +
        "/" +
        this.practiceId +
        "/" +
        this.actionID +
        "/" +
        this.levelId +
        "/" +
        this.pillarName +
        "/" +
        this.plantName +
        "/" +
        parseInt(this.statusId) +
        "/" +
        "editAction"
      );
    },
    editActionAllPillar(pillarId) {
      this.allpillarId = this.pillar;
      let index = this.pillars.findIndex((val) => Number(val.pillarId) === Number(pillarId));
      if (index !== -1) this.pillarName = this.pillars[index].pillarName;

      let indexP = this.plantList.findIndex((val) => Number(val.plantId) === Number(this.plantId));
      if (indexP !== -1) this.plantName = this.plantList[indexP].plantName + ", " + this.plantList[indexP].regionName;
      this.$router.push(
        "/add-new-action/" +
        this.plantId +
        "/" +
        pillarId +
        "&" +
        this.allpillarId +
        "/" +
        this.principleIds +
        "/" +
        this.practiceId +
        "/" +
        this.actionID +
        "/" +
        this.levelId +
        "/" +
        this.pillarName +
        "/" +
        this.plantName +
        "/" +
        parseInt(this.statusId) +
        "/" +
        "editAction"
      );
    },
    // filters

    setFilter() {
      this.selectedPrincipleId = 0;
      this.filterHeader = "Filters";
      let OwnerList = {};
      let StatusList = {};
      let OwnerListA = [];
      let StatusListA = [];
      for (const element of this.backupActionItems) {
        let actionData = JSON.parse(JSON.stringify(element.practiceData));
        actionData.forEach((mainAction) => {
          mainAction.actionItems.forEach((subAction) => {
            if (subAction.ownerName) OwnerList[subAction.ownerName] = subAction.ownerName ? { label: subAction.ownerName, value: subAction.ownerUserId } : "";
            StatusList[subAction.status] = subAction.status ? { label: subAction.status, value: subAction.statusId } : "";
          });
        });
      }
      OwnerListA.push({ label: "All", value: "All", checked: true });
      StatusListA.push({ label: "All", value: "All", checked: true });

      for (const key in OwnerList) {
        if (Object.hasOwnProperty.call(OwnerList, key)) {
          OwnerList[key]["checked"] = true;
          const element = OwnerList[key];
          OwnerListA.push(element);
        }
      }
      for (const key in StatusList) {
        if (Object.hasOwnProperty.call(StatusList, key)) {
          StatusList[key]["checked"] = true;
          const element = StatusList[key];
          StatusListA.push(element);
        }
      }

      this.filterArray = [
        {
          labelName: "Owner",
          arrayValue: OwnerListA,
          id: "Owner",
          multiple: true
        },
        {
          labelName: "Status",
          arrayValue: StatusListA,
          id: "Status",
          multiple: true
        },
        {
          labelName: "Committed Date",
          arrayValue: [{ noCompletedDate: false }, { startInput: null, startDate: null }, { endInput: null, endDate: null }, { restrictMin: this.rMin, restrictMax: this.rMax }],
          id: "CommittedDate",
          forCompletedDate: true
        },
        {
          labelName: "Completed Date",
          arrayValue: [{ noCompletedDate: false }, { startInput: null, startDate: null }, { endInput: null, endDate: null }, { restrictMin: this.rMin, restrictMax: this.rMax }],
          id: "CompletedDate",
          forCompletedDate: true
        }
      ];
      this.filterSelected = this.filterArray;
    },
    setFilterAllPillar() {
      this.selectedPrincipleId = 0;
      this.filterHeader = "Filters";
      let OwnerList = {};
      let StatusList = {};
      let OwnerListA = [];
      let StatusListA = [];
      for (const element of this.backupActionItems) {
        let backupActions = this.backupActionItems;
        console.log(backupActions);

        let backupActionsPillarInfo = element.pillarInfo;
        backupActionsPillarInfo.forEach((items2) => {
          let actionData = JSON.parse(JSON.stringify(items2.practiceData));
          actionData.forEach((mainAction) => {
            mainAction.actionItems.forEach((subAction) => {
              if (subAction.ownerName) OwnerList[subAction.ownerName] = subAction.ownerName ? { label: subAction.ownerName, value: subAction.ownerUserId } : "";
              StatusList[subAction.status] = subAction.status ? { label: subAction.status, value: subAction.statusId } : "";
            });
          });
        });
      }
      OwnerListA.push({ label: "All", value: "All", checked: true });
      StatusListA.push({ label: "All", value: "All", checked: true });

      for (const key in OwnerList) {
        if (Object.hasOwnProperty.call(OwnerList, key)) {
          OwnerList[key]["checked"] = true;
          const element = OwnerList[key];
          OwnerListA.push(element);
        }
      }
      for (const key in StatusList) {
        if (Object.hasOwnProperty.call(StatusList, key)) {
          StatusList[key]["checked"] = true;
          const element = StatusList[key];
          StatusListA.push(element);
        }
      }
      this.filterArray = [
        {
          labelName: "Owner",
          arrayValue: OwnerListA,
          id: "Owner",
          multiple: true
        },
        {
          labelName: "Status",
          arrayValue: StatusListA,
          id: "Status",
          multiple: true
        },
        {
          labelName: "Committed Date",
          arrayValue: [{ noCompletedDate: false }, { startInput: null, startDate: null }, { endInput: null, endDate: null }, { restrictMin: this.rMin, restrictMax: this.rMax }],
          id: "CommittedDate",
          forCompletedDate: true
        },
        {
          labelName: "Completed Date",
          arrayValue: [{ noCompletedDate: false }, { startInput: null, startDate: null }, { endInput: null, endDate: null }, { restrictMin: this.rMin, restrictMax: this.rMax }],
          id: "CompletedDate",
          forCompletedDate: true
        }
      ];
      this.filterSelected = this.filterArray;
    },
    setFilter2() {
      this.filterHeader = "Sort";

      this.filterArray = [
        {
          labelName: "Sort By",
          arrayValue: ["Action Number", "Committed Date", "Completed Date", "Status"],
          id: "Category"
        },
        {
          labelName: "Sort In",
          arrayValue: ["Ascending", "Descending"],
          id: "Order"
        }
      ];
      this.filterSelected = this.filterArray;
    },

    hideModal() {
      this.$refs["my-modal"].hide();
    },
    changedFilter(event) {
      let filterSelectedC = [];
      this.filterArray.map((val, index) => {
        if (val.id === event.target.id && event.target.value !== val.id && val.arrayValue.includes(event.target.value)) {
          let temp = {
            id: val.id,
            arrayValue: [event.target.value],
            labelName: val.labelName
          };

          filterSelectedC.push(temp);
          return filterSelectedC;
        } else if (val.id === event.target.id && val.id === event.target.value) {
          let temp = {
            id: val.id,
            arrayValue: val.arrayValue,
            labelName: val.labelName
          };
          filterSelectedC.push(temp);
          return filterSelectedC;
        } else if (val.id !== event.target.id && event.target.value !== val.id && val.arrayValue.length === this.filterSelected[index]["arrayValue"].length) {
          let temp = {
            id: val.id,
            arrayValue: val.arrayValue,
            labelName: val.labelName
          };
          filterSelectedC.push(temp);

          return filterSelectedC;
        } else {
          filterSelectedC.push(this.filterSelected[index]);
        }
      });
      this.filterSelected = filterSelectedC;
    },
    onContext(event, pos, noCompletedDate) {
      this.checkNoCompletedDate = noCompletedDate;
      if (event && event.selectedDate) {
        switch (pos) {
          case 0:
            this.TargetStartDateInput = event.selectedFormatted;

            break;
          case 1:
            this.TargetEndDateInput = event.selectedFormatted;

            break;
          case 2:
            this.CompletedStartDateInput = event.selectedFormatted;

            break;
          case 3:
            this.CompletedEndDateInput = event.selectedFormatted;

            break;

          default:
            break;
        }
      }
    },

    onApplyClick() {
      if (this.filterHeader === "Sort") {
        this.applySort();
      } else {
        if (this.pillar == -1) {
          this.applyFilterAllPillar();
        } else {
          this.applyFilter();
        }
      }
    },
    applyFilter() {
      let filterSelected = JSON.parse(JSON.stringify(this.filterSelected));
      console.log(filterSelected);
      this.action_item = JSON.parse(JSON.stringify(this.backupActionItems));

      for (let index = 0; index < this.backupActionItems.length; index++) {
        let filteredItems = [];
        let checkedOwner = [];

        this.filterSelected[0]["arrayValue"].map((owner) => {
          if (owner.checked) {
            checkedOwner.push(owner.label);
          }
        });

        //for owner
        if (this.filterSelected.length > 0 && this.filterSelected[0] && this.filterSelected[0]["arrayValue"].length > 0) {
          const arr = this.action_item[index].practiceData;
          for (let i = 0; i < arr.length; i++) {
            const item = arr[i];
            filteredItems.push(item);
            filteredItems[i].actionItems = item.actionItems.filter(({ ownerName }) => {
              return checkedOwner.includes(ownerName);
            });
          }
        }

        //for status
        checkedOwner = [];
        this.filterSelected[1]["arrayValue"].map((status) => {
          if (status.checked) {
            checkedOwner.push(status.label);
          }
        });
        if (this.filterSelected.length > 0 && this.filterSelected[1] && this.filterSelected[1]["arrayValue"].length > 0) {
          const arr = !filteredItems.length ? this.action_item[index].practiceData : filteredItems;
          filteredItems = [];
          arr.forEach((item, i) => {
            filteredItems.push(item);
            filteredItems[i].actionItems = item.actionItems.filter(({ status }) => {
              return checkedOwner.includes(status);
            });
          });
        }

        //for target date
        if (this.filterSelected[2]["arrayValue"][0].noCompletedDate) {
          const arr = !filteredItems.length ? this.action_item[index].practiceData : filteredItems;
          filteredItems = [];
          // this.filterSelected[2].
          arr.forEach((item, i) => {
            filteredItems.push(item);
            filteredItems[i].actionItems = item.actionItems.filter(({ committedDate }) => {
              return !committedDate;
            });
          });
        } else {
          if (this.filterSelected.length > 0 && this.filterSelected[2] && this.filterSelected[2]["arrayValue"].length > 0) {
            const arr = !filteredItems.length ? this.action_item[index].practiceData : filteredItems;
            filteredItems = [];
            arr.forEach((item, i) => {
              filteredItems.push(item);
              filteredItems[i].actionItems = item.actionItems.filter(({ committedDate }) => {
                let cd = new Date(committedDate);
                let toCompareStart = new Date(this.filterSelected[2]["arrayValue"][1].startInput);
                let toCompareEnd = new Date(this.filterSelected[2]["arrayValue"][2].endInput);
                if (this.filterSelected[2]["arrayValue"][1].startInput && this.filterSelected[2]["arrayValue"][2].endInput) {
                  return moment(cd).isAfter(toCompareStart) && moment(toCompareEnd).isAfter(cd);
                } else if (this.filterSelected[2]["arrayValue"][1].startInput && !this.filterSelected[2]["arrayValue"][2].endInput) {
                  return moment(cd).isAfter(toCompareStart);
                } else if (!this.filterSelected[2]["arrayValue"][1].startInput && this.filterSelected[2]["arrayValue"][2].endInput) {
                  return moment(toCompareEnd).isAfter(cd);
                } else {
                  return true;
                }
              });
            });
          }
        }

        //for Completed date
        if (this.filterSelected[3]["arrayValue"][0].noCompletedDate) {
          const arr = !filteredItems.length ? this.action_item[index].practiceData : filteredItems;
          filteredItems = [];
          // this.filterSelected[3].
          arr.forEach((item, i) => {
            filteredItems.push(item);
            filteredItems[i].actionItems = item.actionItems.filter(({ completedDate }) => {
              return !completedDate;
            });
          });
        } else {
          if (this.filterSelected.length > 0 && this.filterSelected[3] && this.filterSelected[3]["arrayValue"].length > 0) {
            const arr = !filteredItems.length ? this.action_item[index].practiceData : filteredItems;
            filteredItems = [];
            arr.forEach((item, i) => {
              filteredItems.push(item);
              filteredItems[i].actionItems = item.actionItems.filter(({ completedDate }) => {
                let cd = new Date(completedDate);
                let toCompareStart = new Date(this.filterSelected[3]["arrayValue"][1].startInput);
                let toCompareEnd = new Date(this.filterSelected[3]["arrayValue"][2].endInput);
                if (this.filterSelected[3]["arrayValue"][1].startInput && this.filterSelected[3]["arrayValue"][2].endInput) {
                  return moment(cd).isAfter(toCompareStart) && moment(toCompareEnd).isAfter(cd);
                } else if (this.filterSelected[3]["arrayValue"][1].startInput && !this.filterSelected[3]["arrayValue"][2].endInput) {
                  return moment(cd).isAfter(toCompareStart);
                } else if (!this.filterSelected[3]["arrayValue"][1].startInput && this.filterSelected[3]["arrayValue"][2].endInput) {
                  return moment(toCompareEnd).isAfter(cd);
                } else {
                  return true;
                }
              });
            });
          }
        }

        this.action_item[index].practiceData = filteredItems;
      }

      this.hideModal();
    },
    applyFilterAllPillar() {
      let filterSelected = JSON.parse(JSON.stringify(this.filterSelected));
      console.log(filterSelected);
      this.action_item = JSON.parse(JSON.stringify(this.backupActionItems));

      let totalfilteredactionCount = 0;
      let filteredactionItemCount = 0;
      for (let index = 0; index < this.backupActionItems.length; index++) {
        let filteredItems = [];
        let checkedOwner = [];
        let checkedStatus = [];
        totalfilteredactionCount = 0;

        //for owner
        const pillarInfo = this.action_item[index].pillarInfo;
        this.filterSelected[0]["arrayValue"].map((owner) => {
          if (owner.checked) {
            checkedOwner.push(owner.label);
          }
        });
        if (this.filterSelected.length > 0 && this.filterSelected[0] && this.filterSelected[0]["arrayValue"].length > 0) {
          pillarInfo.forEach((items, indxPillarInfo) => {
            const arr = items.practiceData;
            filteredactionItemCount = 0;
            filteredItems = [];
            for (let i = 0; i < arr.length; i++) {
              this.action_item[index].pillarInfo[indxPillarInfo].practiceData[i].actionItemsCount = 0;

              const item = arr[i];
              filteredItems.push(item);

              filteredItems[i].actionItems = item.actionItems.filter(({ ownerName }) => {
                return checkedOwner.includes(ownerName);
              });

              if (filteredItems[i].actionItems.length != 0) {
                filteredactionItemCount = filteredItems[i].actionItems.length;
                totalfilteredactionCount += filteredItems[i].actionItems.length;
              }

              this.action_item[index].pillarInfo[indxPillarInfo].practiceData[i].actionItemsCount = filteredactionItemCount;
            }
          });
        }

        filteredactionItemCount = 0;
        totalfilteredactionCount = 0;

        //for status
        const pillarInfo2 = this.action_item[index].pillarInfo;
        this.filterSelected[1]["arrayValue"].map((status) => {
          if (status.checked) {
            checkedStatus.push(status.label);
          }
        });
        if (this.filterSelected.length > 0 && this.filterSelected[1] && this.filterSelected[1]["arrayValue"].length > 0) {
          pillarInfo2.forEach((items, indxPillarInfo) => {
            const arr2 = items.practiceData;
            filteredactionItemCount = 0;
            filteredItems = [];
            for (let i = 0; i < arr2.length; i++) {
              this.action_item[index].pillarInfo[indxPillarInfo].practiceData[i].actionItemsCount = 0;

              const item = arr2[i];
              filteredItems.push(item);
              filteredItems[i].actionItems = item.actionItems.filter(({ status }) => {
                return checkedStatus.includes(status);
              });

              if (filteredItems[i].actionItems.length != 0) {
                filteredactionItemCount = filteredItems[i].actionItems.length;
                totalfilteredactionCount += filteredItems[i].actionItems.length;
              }

              this.action_item[index].pillarInfo[indxPillarInfo].practiceData[i].actionItemsCount = filteredactionItemCount;
            }
          });
        }

        filteredactionItemCount = 0;
        totalfilteredactionCount = 0;

        //for target date
        const pillarInfo3 = this.action_item[index].pillarInfo;
        if (this.filterSelected[2]["arrayValue"][0].noCompletedDate) {
          pillarInfo3.forEach((items, indxPillarInfo) => {
            const arr3 = items.practiceData;
            filteredactionItemCount = 0;
            filteredItems = [];
            for (let i = 0; i < arr3.length; i++) {
              this.action_item[index].pillarInfo[indxPillarInfo].practiceData[i].actionItemsCount = 0;

              const item = arr3[i];
              filteredItems.push(item);
              filteredItems[i].actionItems = item.actionItems.filter(({ committedDate }) => {
                return !committedDate;
              });

              if (filteredItems[i].actionItems.length != 0) {
                filteredactionItemCount = filteredItems[i].actionItems.length;
                totalfilteredactionCount += filteredItems[i].actionItems.length;
              }

              this.action_item[index].pillarInfo[indxPillarInfo].practiceData[i].actionItemsCount = filteredactionItemCount;
            }
          });
        } else {
          if (this.filterSelected.length > 0 && this.filterSelected[2] && this.filterSelected[2]["arrayValue"].length > 0) {
            pillarInfo3.forEach((items, indxPillarInfo) => {
              const arr3 = items.practiceData;
              filteredactionItemCount = 0;
              filteredItems = [];
              for (let i = 0; i < arr3.length; i++) {
                this.action_item[index].pillarInfo[indxPillarInfo].practiceData[i].actionItemsCount = 0;
                const item = arr3[i];
                filteredItems.push(item);
                filteredItems[i].actionItems = item.actionItems.filter(({ committedDate }) => {
                  let cd = new Date(committedDate);
                  let toCompareStart = new Date(this.filterSelected[2]["arrayValue"][1].startInput);
                  let toCompareEnd = new Date(this.filterSelected[2]["arrayValue"][2].endInput);

                  if (this.filterSelected[2]["arrayValue"][1].startInput && this.filterSelected[2]["arrayValue"][2].endInput) {
                    return moment(cd).isAfter(toCompareStart) && moment(toCompareEnd).isAfter(cd);
                  } else if (this.filterSelected[2]["arrayValue"][1].startInput && !this.filterSelected[2]["arrayValue"][2].endInput) {
                    return moment(cd).isAfter(toCompareStart);
                  } else if (!this.filterSelected[2]["arrayValue"][1].startInput && this.filterSelected[2]["arrayValue"][2].endInput) {
                    return moment(toCompareEnd).isAfter(cd);
                  } else {
                    return true;
                  }
                });

                if (filteredItems[i].actionItems.length != 0) {
                  filteredactionItemCount = filteredItems[i].actionItems.length;
                  totalfilteredactionCount += filteredItems[i].actionItems.length;
                }

                this.action_item[index].pillarInfo[indxPillarInfo].practiceData[i].actionItemsCount = filteredactionItemCount;
              }
            });
          }
        }

        filteredactionItemCount = 0;
        totalfilteredactionCount = 0;

        //for Completed date
        const pillarInfo4 = this.action_item[index].pillarInfo;
        if (this.filterSelected[3]["arrayValue"][0].noCompletedDate) {
          pillarInfo4.forEach((items, indxPillarInfo) => {
            const arr4 = items.practiceData;
            filteredactionItemCount = 0;
            filteredItems = [];
            for (let i = 0; i < arr4.length; i++) {
              this.action_item[index].pillarInfo[indxPillarInfo].practiceData[i].actionItemsCount = 0;

              const item = arr4[i];
              filteredItems.push(item);
              filteredItems[i].actionItems = item.actionItems.filter(({ completedDate }) => {
                return !completedDate;
              });

              if (filteredItems[i].actionItems.length != 0) {
                filteredactionItemCount = filteredItems[i].actionItems.length;
                totalfilteredactionCount += filteredItems[i].actionItems.length;
              }

              this.action_item[index].pillarInfo[indxPillarInfo].practiceData[i].actionItemsCount = filteredactionItemCount;
            }
          });
        } else {
          if (this.filterSelected.length > 0 && this.filterSelected[3] && this.filterSelected[3]["arrayValue"].length > 0) {
            pillarInfo4.forEach((items, indxPillarInfo) => {
              const arr4 = items.practiceData;
              filteredactionItemCount = 0;
              filteredItems = [];
              for (let i = 0; i < arr4.length; i++) {
                this.action_item[index].pillarInfo[indxPillarInfo].practiceData[i].actionItemsCount = 0;
                const item = arr4[i];
                filteredItems.push(item);
                filteredItems[i].actionItems = item.actionItems.filter(({ completedDate }) => {
                  let cd = new Date(completedDate);
                  let toCompareStart = new Date(this.filterSelected[3]["arrayValue"][1].startInput);
                  let toCompareEnd = new Date(this.filterSelected[3]["arrayValue"][2].endInput);
                  if (this.filterSelected[3]["arrayValue"][1].startInput && this.filterSelected[3]["arrayValue"][2].endInput) {
                    return moment(cd).isAfter(toCompareStart) && moment(toCompareEnd).isAfter(cd);
                  } else if (this.filterSelected[3]["arrayValue"][1].startInput && !this.filterSelected[3]["arrayValue"][2].endInput) {
                    return moment(cd).isAfter(toCompareStart);
                  } else if (!this.filterSelected[3]["arrayValue"][1].startInput && this.filterSelected[3]["arrayValue"][2].endInput) {
                    return moment(toCompareEnd).isAfter(cd);
                  } else {
                    return true;
                  }
                });

                if (filteredItems[i].actionItems.length != 0) {
                  filteredactionItemCount = filteredItems[i].actionItems.length;
                  totalfilteredactionCount += filteredItems[i].actionItems.length;
                }

                this.action_item[index].pillarInfo[indxPillarInfo].practiceData[i].actionItemsCount = filteredactionItemCount;
              }
            });
          }
        }

        filteredactionItemCount = 0;
        totalfilteredactionCount = 0;
      }

      this.hideModal();
    },
    applySort() {
      let filterSelected = this.filterSelected;
      this.showLoader = true;
      if (filterSelected.length > 0 && filterSelected[0]) {
        if (filterSelected[0]["arrayValue"][0] == "Action Number") {
          this.sort = "actionnumber";
        }
        if (filterSelected[0]["arrayValue"][0] == "Committed Date") {
          this.sort = "committeddate";
        }
        if (filterSelected[0]["arrayValue"][0] == "Completed Date") {
          this.sort = "completeddate";
        }
        if (filterSelected[0]["arrayValue"][0] == "Status") {
          this.sort = "status";
        }
      }
      if (filterSelected && filterSelected[1]) {
        // console.log("2nd sort")
        if (filterSelected[1]["arrayValue"][0] == "Descending") {
          this.order = "dsc";
        } else {
          this.order = "asc";
        }
      }
      if (this.pillar == -1) {
        this.getActionItemAllPillar(0, 0);
      } else {
        this.getActionItem();
      }

      this.hideModal();
    },
    resetFilter() {
      if (this.filterHeader === "Sort") {
        this.showLoader = true;
        this.sort = "";
        this.order = "";
        if (this.pillar == -1) {
          this.getActionItemAllPillar(0, 0);
        } else {
          this.getActionItem();
        }
        this.hideModal();
        //default url
      } else {
        if (this.pillar == -1) {
          this.setFilterAllPillar();
        } else {
          this.setFilter();
        }

        this.action_item = JSON.parse(JSON.stringify(this.backupActionItems));
      }
      this.hideModal();
    },
    onSelect(option, index) {
      let indexLocal = this.filterSelected[index].arrayValue.findIndex((item) => item.label == option.label);

      this.filterSelected[index].arrayValue[indexLocal].checked = !this.filterSelected[index].arrayValue[indexLocal].checked;
      if (option.label == "All" && indexLocal === 0) {
        if (option.checked) {
          for (let i = 1; i < this.filterSelected[index].arrayValue.length; i++) {
            this.filterSelected[index].arrayValue[i].checked = true;
          }
        } else {
          for (let i = 1; i < this.filterSelected[index].arrayValue.length; i++) {
            this.filterSelected[index].arrayValue[i].checked = false;
          }
        }
      }
    },

    onRemove(option, index) {
      if (option.checked === false) {
        if (option.value === "SelectAll" || option.value === "All") {
          this.filterSelected[index].arrayValue[0].checked = true;
          for (const element of this.filterSelected[index].arrayValue) {
            element.checked = true;
          }
        } else {
          let indexL = this.filterSelected[index].arrayValue.findIndex((item) => item.label == option.label);
          this.filterSelected[index].arrayValue[indexL].checked = true;
        }
      } else {
        this.onSelect(option, index);
        if (index === 0) {
          this.filterSelected[1].arrayValue[0].checked = true;
        }
      }
    },
    // filters

    getPillarListData() {
      console.log("getPillarListData");
      let url = Api.PILLARLIST + this.pillar;
      ApiCalls.httpCallDetails(url, "get").then((data) => {
        // //console.log("PillarList ", data);
        this.pillars = [];
        data.data.forEach((i) => {
          if (i.active == "Y") this.pillars.push(i);
        });
      });
    },
    getPlantList() {
      console.log("getPlantList");
      ApiCalls.httpCallDetails(Api.GETPLANTLIST, "get").then((data) => {
        this.plantList = data.data;

        this.plantId = Number(this.$route.params.plantId) ? this.$route.params.plantId : 0;
        if (this.loggedInUserData.regionName && !this.plantId) {
          let plantIndex = this.plantList.findIndex((el) => el.regionName == this.loggedInUserData.regionName);
          if (plantIndex > -1) {
            this.plantId = this.plantList[plantIndex].plantId;
          } //else console.log("*******^");
        }
        this.getPlantDetails();
        this.getPlantPillarDetails();

        if (this.pillar != -1) {
          this.getActionItem();
          this.getActionCount();
        } else {
          this.getActionItemAllPillar(0, 0);
        }
      });
    },
    plantChanged() {
      console.log("plantChanged");
      this.showLoader = true;

      let index = this.plantList.findIndex((data) => data.plantId === this.plantId);
      this.plantName = index !== -1 ? this.plantList[index].plantName + "," + this.plantList[index].regionCode : "";
      this.getPlantDetails();
      this.getPlantPillarDetails();
      // console.log("plant changed plant id " + this.plantId);/
      //console.log("Call getActionCount() from plantChanged()");
      this.getActionCount();
      this.pillar = -1;
      if (this.pillar != -1) {
        this.getActionItem();
      } else {
        this.getActionItemAllPillar(0, 0);
      }

      this.getCommittedActionDetails();
    },
    getPlantDetails() {
      console.log("getPlantDetails");
      ApiCalls.httpCallDetails(Api.PLANTDETAILS + this.plantId, "get").then((data) => {
        this.apiCount++;
        this.plantDetails = data.data;

        if (this.apiCount == 2) {
          this.showLoader = false;
          this.apiCount = 0;
        }
      });
    },
    getPlantPillarDetails() {
      console.log("getPlantPillarDetails");
      ApiCalls.httpCallDetails(Api.PLANTPILLARDETAILS + this.plantId, "get").then((data) => {
        this.apiCount++;
        this.pillarDetails = data.data;
        if (this.apiCount == 2) {
          this.showLoader = false;
          this.apiCount = 0;
        }
      });
    },
    checkToggle(collapsedValue, pillarId) {
      this.collapsed_values[pillarId] = !collapsedValue;
    },
    checkToggleExpandCollapse(toggleName, allPillarIds) {
      let new_collapsed_values = Helper.checkToggleExpandCollapse(toggleName, allPillarIds, this.collapsed_values);
      this.collapsed_values = new_collapsed_values;
    },
    onchangepillar(data) {
      if (data !== "0") {
        if (data === "-1") {
          this.showLoader = true;
          this.getActionItemAllPillar(0, 0);
          this.getActionCountAllPillar();
        } else {
          this.showLoader = true;
          this.getActionItem();
          this.getActionCount();
        }
      } else {
        this.action_item = [];
        this.totalComp = 0;
        this.totalCommitted = 0;
      }
    },
    committed_year_changed(e) {
      console.log("committed_year_changed");
      console.log("PlantId: " + this.plantId);
      console.log("PillarId: " + this.pillar);
      let val = e.target.value;
      this.committed_year = val;
      this.rMin = new Date(this.committed_year + "-01-01");
      this.rMax = new Date(this.committed_year + "-12-31");
      this.showLoader = true;
      if (this.pillar === "-1" || this.pillar === -1) {
        this.getActionItemAllPillar(0, 0);
        this.getActionCountAllPillar();
        this.getCommittedActionDetails();
      } else {
        this.getActionItem();
        this.getActionCount();
        this.getCommittedActionDetails();
      }
    },
    getPracticesCount(pillarId, pillarName, pillarInfoDetails) {
      console.log("getPracticesCount");
      let actionItemsCount = 0;
      pillarInfoDetails.forEach((pInfo, indxPInfo) => {
        let practiceData = pInfo.practiceData;
        if (practiceData.length > 0) {
          practiceData.forEach((practiceDataInfo, indxPracticeInfo) => {
            actionItemsCount += practiceDataInfo.actionItemsCount;
          });
        }
      });

      console.log("Pillar: " + pillarName + " | practicesCount: " + actionItemsCount);
      if (actionItemsCount < 10) {
        console.log(actionItemsCount);
      }

      this.$set(this.practices_Count, pillarId, actionItemsCount);
      console.log(this.practices_Count);
      return actionItemsCount;
    },
    getActionItemAllPillar(comMonth, compMonth) {
      console.log("getActionItemAllPillar");
      console.log("this.plantId: " + this.plantId);
      console.log("this.pillarId: " + this.pillar);
      if (this.plantId === "0" || this.plantId === 0 || this.plantId === "" || this.plantId === undefined || this.plantId === null) {
        this.plantId = 1;
      }


      this.allPillarCodes = [];
      this.allPillarIds = [];
      this.showLoader = true;
      let url = Api.GETACTIONITEMALLPILLARS + this.plantId + "/" + this.committed_year + "/" + comMonth + "/" + compMonth + "/";

      ApiCalls.httpCallDetails(url, "get").then((data) => {
        if (data.success === true) {
          this.success = data.success;
          this.action_item = data.data;
          this.backupActionItems = data.data;

          //for expand/collapse all
          this.action_item.forEach((value, indx) => {
            this.allPillarIds.push(value.pillarId);
            this.allPillarCodes.push(value.pillarCode);
            this.$set(this.collapsed_values, value.pillarId, true);
          });

          this.setFilterAllPillar();
          this.showLoader = false;
        }
      });
    },
    getActionItem() {
      console.log("getActionItem");

      if (this.plantId != 0 && this.pillar != 0) {
        let url = Api.GETACTIONITEM + this.plantId + "/" + this.pillar + "/" + this.committed_year + "?SortCategory=" + this.sort + "&&Order=" + this.order;

        ApiCalls.httpCallDetails(url, "get").then((data) => {
          this.showLoader = false;
          if (data.success === true) {
            // console.log("GETACTIONITEM ", data);
            //  this.showLoader= !data.success,
            this.success = data.success;
            this.action_item = data.data;
            this.backupActionItems = data.data;
            this.setFilter();
          }
        });
      } else {
        this.action_item = [];
      }
    },
    getActionCount() {
      console.log("getActionCount");
      let url = Api.GETCOUNTSUMMARY + this.plantId + "/" + this.pillar;
      ApiCalls.httpCallDetails(url, "get").then((data) => {
        this.actionReq = data.success ? data.data.actionRequiredToAchieveGoal : 0;
        this.totalComp = data.success ? data.data.totalCompleted : 0;

        this.totalCommitted = data.success ? data.data.totalCommittedActions : 0;
        this.currentYear = data.data.currentYear;
      });
    },
    getActionCountAllPillar() {
      let url = Api.GETCOUNTSUMMARYALLPILLAR + this.plantId;
      ApiCalls.httpCallDetails(url, "get").then((data) => {
        this.actionReq = data.success ? data.data.actionRequiredToAchieveGoal : 0;
        this.totalComp = data.success ? data.data.totalCompleted : 0;

        this.totalCommitted = data.success ? data.data.totalCommittedActions : 0;
        this.currentYear = data.data.currentYear;
      });
    },
    iconSelected(statusName) {
      // console.log(statusName)
      switch (statusName) {
        case "Committed":
          return Committed;
        case "Completed":
          return Completed;
        case "No Committed Date":
          return NotCommitedDate;
        case "Off Track":
          return OffTrack;
        case "On Track":
          return OnTrack;
        case "In Progress":
          return InProgress;
        case "Not Started":
          return NotStarted;
        case "Not Sustained":
          return NotSustained;
        case "Major Changes":
          return MajorChanges;
        case "Deleted":
          return Deleted;
      }
    }
  }
};
</script>

<style scoped>
.top-card {
  width: 7rem;
}

.action-btn {
  border: 1px #274ba7 solid;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50px;
  width: 32px;
  cursor: pointer;
  margin-right: 20px;
}

.acti-item-filter {
  /* width: 68%; */
  display: flex;
  /* margin-top: -5px; */
  flex-direction: row-reverse;
  /* margin-left: 10%; */
  /* position: absolute; */
  right: 30px;
  top: 45px;
}

.funnel-con {
  font-size: 16px;
}

#edit-li:disabled {
  cursor: not-allowed;
  opacity: 0.3;
}

#edit-li {
  width: 100%;
  text-align: left;
  border: none;
  background: none;
}

#editButton:disabled {
  cursor: not-allowed;
  opacity: 0.3;
}

.action-table th div {
  text-align: center !important;
  vertical-align: middle !important;
  font-weight: 100;
}

.action-table:first-child {
  padding: 2px;
  text-align: center !important;
  font-size: 1.166rem;
  font-family: "BarlowM", sans-serif;
  line-height: 1.25rem;
  /* font-weight: 200; */
}

.action-table thead th,
.action-table td {
  text-align: center !important;
  vertical-align: middle !important;
}

.action-table tbody tr,
.action-table td {
  font-family: "BarlowR", sans-serif;
  font-size: 1.1666666666666667rem;
  line-height: 1.6666666666666667rem;
  vertical-align: middle;
  text-align: center;
}

.ellipse-text {
  vertical-align: middle;
  font-family: "BarlowR", sans-serif;
  font-size: 1.3rem;
  color: #ffffff;
  line-height: 1.6666666666666667rem;
}

.principle-name-label {
  margin-top: 5px;
  vertical-align: middle;
  font-size: 1.33rem;
  font-family: "BarlowM", sans-serif;
  line-height: 1.6666666666666667rem;
}

.btn-action {
  background-color: #274ba7;
  font-family: "BarlowM", sans-serif;
  line-height: 1.6666666666666667rem;
  font-size: 1rem;
}

.no-data {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  align-content: center;
  align-items: center;
  height: 8rem;
  justify-content: space-evenly;
}

.ellipse {
  border-radius: 100%;
  width: 30px;
  height: 30px;
  background: #687b9b 0% 0% no-repeat padding-box;

  padding: 4px;
}

.main-con {
  background: #fff;
  margin-top: 1em;
  border-radius: 6px;
}

.mat-info-con {
  padding: 2rem;
  display: flex;
}

.plant-dd {
  width: 20.5%;
}

.plant-select {
  height: 54px;
  border-color: #313131;
}

.plant-card {
  background: #fffce3;
  height: 54px;
  margin-right: 1rem;
  text-align: center;
  color: #414141;
  padding: 8px;
  border-radius: 7px;
}

.pillar-count {
  font-size: 1rem;
  font-family: "BarlowB", sans-serif;
  padding: 0.25rem;
  background-color: #d55050 !important;
  color: #fff;
  border-radius: 50%;
  margin-left: 0.4rem;
  padding-right: 0.4rem;
  padding-left: 0.4rem;
}

.pillar-count-hide {
  display: none;
}

.mm-menu {
  margin-right: 0rem;
}

.level-row {
  text-align: center;
  background: #eff3ff;
  border: 1px solid #ebecef;
  border-radius: 5px 5px 0px 0px;
  padding-top: 1em;
  padding-bottom: 1em;
  padding-left: 10px;
  margin-left: 0px;
  margin-right: 0px;
}

.level-status {
  color: #454545;
}

.level-date {
  opacity: 0.7;
}

.modify-date {
  position: absolute;
  top: 167px;
  right: 50px;
}

.dropdown-custome .dropdown-menu {
  min-width: 2rem;
}

.mat-stats-con {
  display: flex;
  padding-left: 2rem;
  padding-top: 0.5em;
  padding-bottom: 0.5em;
  align-items: center;
}

.mat-status-con {
  margin-left: 2rem;
  display: flex;
  align-items: center;
}

.asmt-bt {
  border-color: #869ed8 !important;
  background: #eff3ff 0% 0% no-repeat padding-box !important;
  color: #274ba7 !important;
}

.asmt-bt:hover {
  border-color: #869ed8 !important;
  color: #274ba7 !important;
  background: #cad0e4 0% 0% no-repeat padding-box !important;
}

.last-ast-date {
  padding-top: 0.5rem;
  color: #5a5a5a;
}

.ast-action {
  display: flex;
  justify-content: center;
}

.take-ast-btn {
  margin-right: 1rem;
}

@media only screen and (max-width: 575px) {
  .mat-stats-con {
    display: block !important;
  }

  .mat-status-con {
    margin-left: 0px !important;
  }
}

@media only screen and (max-width: 767px) {
  .mat-info-con[data-v-dcabda2e] {
    display: block !important;
  }

  .plant-dd[data-v-dcabda2e] {
    width: 100% !important;
  }

  .plant-card[data-v-dcabda2e] {
    width: 100% !important;
    margin-left: 0em !important;
    margin-top: 1em;
  }

  .modify-date {
    top: 437px !important;
  }
}
</style>
