<template ref="my-modal">
  <div>
    <div class="filter">
      <div class="filter-header-font-style primary-color filter-name">
        {{ filterHeader }}
      </div>
      <div class="act-con">
        <div class="close" @click="hideModal">
          <b-icon icon="x" aria-hidden="true"></b-icon>
        </div>
        <div>
          <b-button pill class="page-heading-dropdown-content aplly-btn mr-10" @click="applyFilter">
            Apply
          </b-button>
        </div>
        <div class="page-heading-dropdown-content primary-color mr-10" @click="resetFilter">
          Reset
        </div>
      </div>
    </div>
    <hr class="dividera" />
    <div class="filter-con">
      <div class="mb-3 fc-con" v-for="(item, index) in filterArray" v-bind:key="item.id">
        <!-- dropdown filter + multi select -->
        <label v-if="!item.multiple && !item.forCompletedDate && !item.forTargetDate" :for="item.id"
          class="form-label filter-content-font-style lable-text">
          {{ item.labelName }}
        </label>
        <select v-if="!item.multiple && !item.forCompletedDate && !item.forTargetDate" class="
            form-control
            font-style-select
            filter-header-font-style
            cust-ft-icon
          " :id="item.id" v-model="selection[index]" @change="changedFilter">
          <option :value="item.id">Select {{ item.id }}</option>
          <option v-for="arrayValue in item.arrayValue" :key="arrayValue" :value="arrayValue">
            {{ arrayValue }}
          </option>
        </select>
        <div id="id_multi" v-if="item.multiple && item.multiple === true"
          :class="{ 'height-increase': item.arrayValue.length >= 10 }" style="height: 15rem">
          <Multiselect :id="item.id" class="form-control font-style-select filter-header-font-style"
            :placeholder="'Select ' + item.labelName" v-model="dataArray[item.id]" :options="item.arrayValue"
            label="label" track-by="value" :multiple="true" :close-on-select="false" :clear-on-select="false"
            :preserve-search="false" :preselect-first="false" @select="onSelect($event, index)"
            @remove="onRemove($event, index)">
            <span class="checkbox-label" slot="option" slot-scope="scope" @click.self="select(scope.option)">
              <label style="margin-left: 1rem">
                <input class="test" type="checkbox" v-model="scope.option.checked" @focus.prevent />
                {{ scope.option.label }}
              </label>
            </span>
          </Multiselect>
        </div>
        <!-- dropdown filter + multi select -->

        <!-- date range filter -->
        <div id="id_date-range" v-if="item.forTargetDate && item.forTargetDate === true">
          <label :for="item.id" class="form-label filter-content-font-style" style="z-index: 1">
            {{ item.labelName }}
          </label>
          <div style="
              display: flex;
              justify-content: space-between;
              align-items: center;
            ">
            <div style="width: 45%">
              <b-input-group>
                <b-form-input id="plant-associate-feedback-start-date-input" v-model="item.arrayValue[0].startInput"
                  type="text" placeholder="Start Date" autocomplete="off" class="input-target-completion-date" disabled>
                </b-form-input>
                <b-input-group-append>
                  <b-form-datepicker id="plant-associate-feedback-start-date-setting"
                    v-model="item.arrayValue[0].startDate" button-only right locale="en-US"
                    aria-controls="plant-associate-feedback-start-date-input" dark label-no-date-selected="Start Date"
                    :min="item.arrayValue[3].restrictMin" :max="item.arrayValue[3].restrictMax" :date-format-options="{
                      month: '2-digit',
                      day: '2-digit',
                      year: 'numeric',
                    }" @context="contextChange($event, index + '0')">
                  </b-form-datepicker>
                </b-input-group-append>
              </b-input-group>
            </div>
            <div class="form-label filter-content-font-style">To</div>
            <div style="width: 45%">
              <b-input-group>
                <b-form-input id="plant-associate-feedback-start-date-input" v-model="item.arrayValue[1].endInput"
                  type="text" placeholder="End Date" autocomplete="off" class="input-target-completion-date" disabled>
                </b-form-input>
                <b-input-group-append>
                  <b-form-datepicker id="plant-associate-feedback-start-date-setting"
                    v-model="item.arrayValue[1].endDate" button-only right :min="minDate['M' + index + '1']"
                    :max="item.arrayValue[3].restrictMax" locale="en-US"
                    aria-controls="plant-associate-feedback-start-date-input" dark label-no-date-selected="End Date"
                    :date-format-options="{
                      month: '2-digit',
                      day: '2-digit',
                      year: 'numeric',
                    }" @context="contextChange($event, index + '1')">
                  </b-form-datepicker>
                </b-input-group-append>
              </b-input-group>
            </div>
          </div>
        </div>
        <div id="id_date-range" v-if="item.forCompletedDate && item.forCompletedDate === true">
          <div style="
              display: flex;
              align-items: center;
              justify-content: space-between;
            ">
            <label :for="item.id" class="form-label filter-content-font-style" style="z-index: 1">
              {{ item.labelName }}
            </label>
            <label style="
                display: flex;
                justify-content: space-between;
                align-items: center;
                width: 11rem;
              ">

              <b-form-checkbox v-model="item.arrayValue[0].noCompletedDate"
                @change="contextChange($event, index + 'C')"></b-form-checkbox>
              {{ "No "+item.labelName }}
            </label>
          </div>
          <div style="
              display: flex;
              justify-content: space-between;
              align-items: center;
            ">
            <div style="width: 45%">
              <b-input-group>
                <b-form-input id="plant-associate-feedback-start-date-input" v-model="item.arrayValue[1].startInput"
                  type="text" placeholder="Start Date" autocomplete="off" class="input-target-completion-date" disabled>
                </b-form-input>
                <b-input-group-append>
                  <b-form-datepicker id="plant-associate-feedback-start-date-setting"
                    v-model="item.arrayValue[1].startDate" button-only right locale="en-US"
                    aria-controls="plant-associate-feedback-start-date-input" dark :min="item.arrayValue[3].restrictMin"
                    :max="item.arrayValue[3].restrictMax" label-no-date-selected="Start Date" :date-format-options="{
                      month: '2-digit',
                      day: '2-digit',
                      year: 'numeric',
                    }" @context="contextChange($event, index + '2')" :disabled="noCompletedDate">
                  </b-form-datepicker>
                </b-input-group-append>
              </b-input-group>
            </div>
            <div class="form-label filter-content-font-style">To</div>
            <div style="width: 45%">
              <b-input-group>
                <b-form-input id="plant-associate-feedback-start-date-input" v-model="item.arrayValue[2].endInput"
                  type="text" placeholder="End Date" autocomplete="off" class="input-target-completion-date" disabled>
                </b-form-input>
                <b-input-group-append>
                  <b-form-datepicker id="plant-associate-feedback-start-date-setting"
                    v-model="item.arrayValue[2].endDate" button-only right :min="minDate['M' + index + '2']"
                    :max="item.arrayValue[3].restrictMax" locale="en-US"
                    aria-controls="plant-associate-feedback-start-date-input" dark label-no-date-selected="End Date"
                    :date-format-options="{
                      month: '2-digit',
                      day: '2-digit',
                      year: 'numeric',
                    }" :disabled="noCompletedDate" @context="contextChange($event, index + '3')">
                  </b-form-datepicker>
                </b-input-group-append>
              </b-input-group>
            </div>
          </div>
        </div>
        <!-- date range filter -->
      </div>
    </div>
  </div>
</template>
<script>
import Multiselect from "vue-multiselect";
export default {
  name: "ActionHomeScreenFilter",
  props: {
    hideModal: Function,
    filterArray: [],
    applyFilter: Function,
    resetFilter: Function,
    changedFilter: Function,
    filterSelected: [],
    onSelect: Function,
    onRemove: Function,
    onContext: Function,
    filterHeader: String,
  },
  components: {
    Multiselect,
  },
  data() {
    return {
      selection: [],
      dataArray: {
        Year: [],
        Region: [],
        PBU: [],
        PlantName: [],
        Pillar: [],
        PrincipleNumber: [],
        Level: [],
      },
      minDate: {},
      noCompletedDate: false,
      CompletedStartDateInput: null,
      CompletedStartDate: null,
      CompletedEndDateInput: null,
      CompletedEndDate: null,
      TargetStartDateInput: null,
      TargetStartDate: null,
      TargetEndDateInput: null,
      TargetEndDate: null,
    };
  },
  mounted() {
    this.selection = [];
    this.filterSelected.map((val) => {
      if (val.arrayValue.length === 1 && !val.multiple)
        this.selection.push(val.arrayValue[0]);
      else if (val.arrayValue.length > 1 && !val.multiple)
        this.selection.push(val.id);
    });
    this.dataArray = {
      Year: [],
      Region: [],
      PBU: [],
      PlantName: [],
      Pillar: [],
    };
  },
  methods: {
    contextChange(event, pos) {
      if (event && event.selectedDate) {
        switch (Number(pos[1])) {
          case 0:            
            this.filterSelected[pos[0]].arrayValue[0].startInput =
              event.selectedFormatted;
            this.minDate["M" + pos[0] + "1"] = new Date(
              event.selectedFormatted
            );
            break;
          case 1:            
            this.filterSelected[pos[0]].arrayValue[1].endInput =
              event.selectedFormatted;
            break;
          case 2:
            this.filterSelected[pos[0]].arrayValue[1].startInput =
              event.selectedFormatted;
            this.minDate["M" + pos[0] + "2"] = new Date(
              event.selectedFormatted
            );
            break;
          case 3:            
            this.filterSelected[pos[0]].arrayValue[2].endInput =
              event.selectedFormatted;
            break;

          default:
            break;
        }
      } else if (event && event.target) {
        this.filterSelected[pos[0]].arrayValue[0].noCompletedDate =
          event.target.checked;
      }
      this.filterSelected.map((val) => {
        if (val.arrayValue.length === 1 && !val.multiple)
          this.selection.push(val.arrayValue[0]);
        else if (val.arrayValue.length > 1 && !val.multiple)
          this.selection.push(val.id);
      });
      this.onContext(event, pos, this.noCompletedDate);
    },
    showModal() {      
    },
  },
};
</script>
<style scoped>
.cust-ft-icon {
  background: url(/src/assets/Arrow_Dropdown.svg) no-repeat 98% center;
  -webkit-appearance: none;
  padding-right: 2rem;
}

.cust-ft-icon:disabled {
  background: url(/src/assets/Drop-down_disabled.svg) no-repeat 98% center;
  -webkit-appearance: none;
  padding-right: 2rem;
}

.font-style-select {
  font-size: 1.3333333333333333rem;
  font-family: "BarlowR", sans-serif;
}

.checkbox-label {
  display: inline-block;
}

.test {
  position: relative;
  display: inline-block;
  margin-bottom: 0.5rem;
}

.filter {
  display: flex;
  padding-left: 2rem;
  padding-top: 1rem;
  padding-bottom: 1rem;
  padding-right: 1rem;
}

.filter-name {
  font-weight: 600;
  width: 50%;
}

.act-con {
  display: flex;
  flex-direction: row-reverse;
  width: 50%;
  align-items: center;
  margin-top: -6px;
}

.close {
  color: "#E6E6E6";
}

.aplly-btn {
  background: #274ba7;
  border-color: #274ba7;
  width: 5em;
}

.filter-con {
  padding-left: 2rem;
  padding-right: 2rem;
}

.filter-con .form-control {
  border: 1px solid #313131;
  height: 2.8em;
  color: #274ba7;
  font-weight: 600;
}

.form-label {
  color: #274ba7;
}

.filter-con .form-control:focus {
  box-shadow: 0 0 0 0rem rgb(0 123 255 / 25%);
}

.lable-text {
  position: absolute;
  background: #fff;
  margin: -0.5rem 0 0 0.7rem;
  padding-right: 5px;
  padding-left: 5px;
}

.fc-con {
  margin-top: 1.5rem;
}

.dividera {
  margin-top: 0rem !important;
}

#plant-associate-feedback-start-date-setting:disabled {
  opacity: 1 !important;
}

/* select {
      background: url(http://cdn1.iconfinder.com/data/icons/cc_mono_icon_set/blacks/16x16/br_down.png) no-repeat right #fff;
      -webkit-appearance: none;
      background-position-x: 244px;
  } */
</style>